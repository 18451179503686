body {
  height: 100%;
  margin: 0;

  #__next {
    height: 100%;
  }
}

#root {
  height: 100%;
}
